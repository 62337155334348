document.addEventListener('DOMContentLoaded', function () {
  const histoire = document.querySelector('.histoire');
  const dates = histoire.querySelectorAll(
    '.histoire__content > div:not(.histoire__start)'
  );
  const hoverZones = histoire.querySelectorAll('.hover-zone');
  const overlays = histoire.querySelectorAll('.overlay');
  const popups = histoire.querySelectorAll('.popup');

  let animationInProgress = false; // Variable pour contrôler si une animation est en cours

  gsap.set(popups, { opacity: 0 });

  function setOverlayInitialBottom() {
    dates.forEach((date, index) => {
      const overlay = date.querySelector('.overlay');
      const overlayInitialBottom =
        histoire.getBoundingClientRect().bottom -
        date.getBoundingClientRect().bottom;
      gsap.set(overlay, { bottom: -overlayInitialBottom, height: 0 });
    });
  }

  setOverlayInitialBottom();

  window.addEventListener('resize', setOverlayInitialBottom);

  dates.forEach((date, index) => {
    const hoverZone = date.querySelector('.hover-zone');
    const overlay = date.querySelector('.overlay');
    const popup = date.querySelector('.popup');

    hoverZone.addEventListener('mouseenter', () => {
      gsap.killTweensOf([overlay, popup]); // Stop current animation

      const tl = gsap.timeline({ ease: 'ease-out' });
      tl.to(overlay, { height: histoire.offsetHeight, duration: 0.4 }).to(
        popup,
        {
          opacity: 1,
          duration: 0.3,
        }
      );
    });

    overlay.addEventListener('mouseleave', () => {
      gsap.killTweensOf([overlay, popup]); // Stop current animation

      const tl = gsap.timeline({ ease: 'ease-out' });
      tl.to(overlay, { height: '0', duration: 0.4 }).to(popup, {
        opacity: 0,
        duration: 0.3,
      });
    });
  });
});
